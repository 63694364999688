<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>审核</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="审核状态" prop="auditStatus" style="width: 300px;">
        <a-radio-group button-style="solid" v-model="form.auditStatus">
          <a-radio-button :value="1">通过</a-radio-button>
          <a-radio-button :value="2">拒绝</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="审核描述" prop="auditDescription" style="width: 300px;">
        <a-textarea v-model="form.auditDescription" :rows="4" placeholder="请输入审核描述"/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { updatePushRecord } from '@/api/valve/messageAndPush'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      // 表单参数
      form: {
        id: null,
        auditStatus: 1,
        auditDescription: '',
        status: 0
      },
      open: false,
      rules: {
        auditStatus: [
          { required: true, message: '请选择审核状态', trigger: ['blur', 'change'] }
        ]
        // auditDescription: [
        //   { required: true, message: '请输入审核描述', trigger: ['blur'] }
        // ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: null,
        auditStatus: 1,
        auditDescription: '',
        status: 0
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (id) {
      this.form = {
        id: id,
        auditStatus: 1,
        auditDescription: '',
        status: 0
      }
      this.open = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          updatePushRecord(this.form).then(response => {
            this.$message.success(
              '审核成功',
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
