import request from '@/utils/request'

// 查询消息信息分页
export function pageInformation(query) {
  return request({
    url: '/tl/tl-information/page',
    method: 'get',
    params: query
  })
}

// 查询消息信息详细
export function getInformation(data) {
  return request({
    url: '/tl/tl-information/detail',
    method: 'get',
    params: data
  })
}

// 新增消息信息
export function addInformation(data) {
  return request({
    url: '/tl/tl-information/add',
    method: 'post',
    data: data
  })
}

// 修改消息信息
export function updateInformation(data) {
  return request({
    url: '/tl/tl-information/edit',
    method: 'post',
    data: data
  })
}

// 删除消息信息
export function delInformation(data) {
  return request({
    url: '/tl/tl-information/delete',
    method: 'post',
    data: data
  })
}

// 查询推送记录分页
export function pagePushRecord(query) {
  return request({
    url: '/tl/tl-push-record/page',
    method: 'get',
    params: query
  })
}

// 查询推送记录详细
export function getPushRecord(data) {
  return request({
    url: '/tl/tl-push-record/detail',
    method: 'get',
    params: data
  })
}

// 新增推送记录
export function addPushRecord(data) {
  return request({
    url: '/tl/tl-push-record/add',
    method: 'post',
    data: data
  })
}

// 修改推送记录
export function updatePushRecord(data) {
  return request({
    url: '/tl/tl-push-record/edit',
    method: 'post',
    data: data
  })
}

// 删除推送记录
export function delPushRecord(data) {
  return request({
    url: '/tl/tl-push-record/delete',
    method: 'post',
    data: data
  })
}
